/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useMemo } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types';

// @mui material components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// Material Dashboard 2 PRO React components
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React examples
import SalesTableCell from 'examples/Tables/SalesTable/SalesTableCell';

function SalesTable({ rows, shadow }) {
  let defaultCountries = [
    {
      country: ['AU', 'Australia'],
      registered: 0,
      active: 0
    },
    {
      country: ['JP', 'Japan'],
      registered: 0,
      active: 0
    },
    {
      country: ['KR', 'South Korea'],
      registered: 0,
      active: 0
    },
    {
      country: ['US', 'United States'],
      registered: 0,
      active: 0
    }
  ];

  const rowsToShow = [...rows.slice(1)];
  defaultCountries = defaultCountries.filter((c) =>
    rowsToShow.find((cc) => c.country[0] === cc.country[0] && (cc.registered === 0 || cc.active === 0))
  );
  const rowsDescending = [...defaultCountries, ...rowsToShow].sort((a, b) => b.active - a.active);

  const renderTableCells = rowsDescending.slice(0, 4).map((row, key) => {
    const tableRows = [];
    const rowKey = `row-${key}`;
    Object.entries(row).map(([cellTitle, cellContent]) => {
      Array.isArray(cellContent)
        ? tableRows.push(
            <SalesTableCell
              key={cellContent[1]}
              title={cellTitle}
              content={cellContent[1]}
              image={`https://flagsapi.com/${cellContent[0]}/shiny/64.png`}
              noBorder={key === rows.length - 1}
            />
          )
        : tableRows.push(
            <SalesTableCell
              key={cellContent}
              title={cellTitle}
              content={cellContent}
              noBorder={key === rows.length - 1}
            />
          );
    });

    return <TableRow key={rowKey}>{tableRows}</TableRow>;
  });

  return (
    <TableContainer sx={{ height: '100%', boxShadow: !shadow && 'none' }}>
      <Table>
        <TableBody>{useMemo(() => renderTableCells, [rows])}</TableBody>
      </Table>
    </TableContainer>
  );
}

// Setting default values for the props of SalesTable
SalesTable.defaultProps = {
  title: '',
  rows: [{}],
  shadow: true
};

// Typechecking props for the SalesTable
SalesTable.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.arrayOf(PropTypes.object),
  shadow: PropTypes.bool
};

export default SalesTable;
